// tara
export interface WebRtcDevices {
  camera: WebRtcDevice
  microphone: WebRtcDevice
}

export interface WebRtcDevice {
  label: string
  hardwareId: string
  rank: number
  type: string
}

export enum TaraMessage {
  INIT = 'taraInit',
  SET_SIGNATURE = 'setTaraSignature',
  RECEIVED_SIGNATURE = 'receivedSignature',
  EDITS = 'taraEdits',
  NEW_PDF_PAGE = 'taraNewPdfPage',
  INIT_ACK = 'taraInitAck',
  RECONNECT_SYNC = 'taraReconnectSync',
  RECONNECT_SYNC_ACK = 'taraReconnectSyncAck',
  KICK_USER = 'kickUser',
  CONSULTATION_FINISH = 'taraConsultationFinished'
}

// messages coming out of the iframe
export interface IResponseMessage {
  reqId: string
  success: boolean
  response: string
}
export interface ISubscribedMessage {
  content: ApiSubscriptionTypes
  status?: string
  message?: string
  signalingEvent?: ApiSignalingEvents
}

// messages coming from external into the iframe
export interface IExternalMessage {
  reqId: string
  type: ApiMessageTypes
}
export interface IPatientData extends IExternalMessage{
  firstName: string
  lastName: string
  gender: string
  dateOfBirth?: string
}
export interface ISubscribtionMessage extends IExternalMessage{
  event: ApiSubscriptionTypes
  token?: string
}
export interface datachannelCommandMessage {
  cmd?: string
  devices?: WebRtcDevices
  data?: string
  msgtype?: string
}

export interface RequestPayload {
  target: RequestTargets
}

export interface RequestMessage extends IExternalMessage, RequestPayload{
}

// internal messages
export interface ISignalingEventMessage {
  type: ApiSignalingEvents
  body: string
}

/**
 * Message structure for TeleDocHandler operations
 * via the IFrameApi.
 * Operation = which method to call.
 * parameters = additional data, maybe a json string to fill parameters.
 */
export interface ITeleDocIFrameCommand extends IExternalMessage {
  operation: ITeleDocOperation,
  parameters?: string
}

export enum ITeleDocOperation {
  INIT = 'init',
  HIDECONTROLINTERFACE = 'hideControlInterface',
  RESTORECONTROLINTERFACE = 'restoreControlInterface',
  ENABLELICENSEFEATURES = 'enableLicenseFeatures',
  APPLYZOOMDATA =  'applyZoomData',
  SENDSTETHCOMMAND = 'sendStethCommand'
}

// various message enums
export enum ApiMessageTypes {
  GET = 'get',
  INIT = 'init',
  PATIENT_MASTER_DATA = 'patientMasterData',
  DATA_CHANNEL_MSG = 'dataChannelMsg',
  CAM_AND_MIC_STATE = 'camAndMicState',
  SCROLL_TO_TOP = 'scrollToTop',
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
  TELEDOC_API = 'teledoc_api'
}

export enum ApiSubscriptionTypes {
  STATUS = 'status',
  DATACHANNEL = 'datachannel',
  SIGNALING = 'signaling',
  ERROR = 'error',
  SERVERMESSAGE = 'servermessage',
  INSUFFICIENT_MEDIA = 'insufficientMedia',
  CONNECTION_STATE_CHANGE = 'connection_state_change'
}

export enum ApiSignalingEvents {
  ENTERED_ROOM ='entered_room',
  JOINED = 'joined',
  DISCONNECT = 'disconnect',
  ERROR = 'error'
}

export enum WebAppState {
  LOGIN = 'login',
  VIDEO= 'video',
  UNKNOWN = 'unknown'
}

export enum RequestTargets {
  STATE = 'state',
}

// type guards
export function isResponseMessage (object: any): object is IResponseMessage {
  return 'reqId' in object && typeof object.reqId === 'string' && 'success' in object && typeof object.success === 'boolean';
}
export function isSubscribedMessage (object: any): object is ISubscribedMessage {
  return 'content' in object && typeof object.content === 'string';
}
export function isExternalMessage (object: any): object is IExternalMessage {
  return 'reqId' in object && typeof object.reqId === 'string' && 'type' in object && typeof object.type === 'string';
}
export function isSubscribtionMessage (object: any): object is ISubscribtionMessage {
  const t = object; // necessary workaround
  return isExternalMessage(t) && 'event' in object && typeof object.event === 'string';
}
