import { ApiMessageTypes, IResponseMessage, WebAppState } from '../../api/iframeAPI/iframeApiInterfaces';

export class StateObserver {
  private static _instance: StateObserver;

  /**
     * Private constructor to enforce singleton pattern.
     */
  private constructor () {
    window.addEventListener('message', this.receiveMessage.bind(this), false);
  }

  /**
     * Returns the instance of the StateObserver class.
     * If an instance already exists, it returns the existing instance; otherwise, it creates a new instance.
     * @returns The instance of the StateObserver class.
     */
  public static getInstance (): StateObserver {
    return this._instance || (this._instance = new this());
  }

  /**
     * Receives messages from the parent window and handles them accordingly.
     * @param ev - The message event.
     */
  public receiveMessage (ev: any): void {
    if (typeof ev.data === 'object' && ev.data.type === ApiMessageTypes.GET) {
      switch (ev.data.target) {
        case 'state':
          this.sendMessage(ev.data.reqId, this.getState()); break;
      }
    }
  }

  /**
     * Sends a message to the parent window.
     * @param reqId - The request ID.
     * @param type - The message type.
     * @param data - The message data.
     */
  public sendMessage (reqId: string, data: string): void {
    const message: IResponseMessage = { reqId: reqId, success: true, response: data };
    window.parent.postMessage(message, '*');
  }

  /**
     * Returns the current state of the application based on the window location.
     * @returns The current state of the application.
     */
  private getState (): WebAppState {
    switch (document.location.pathname) {
      case '/login_tara':
        return WebAppState.LOGIN;
      case '/login':
        return WebAppState.LOGIN;
      case '/privacy':
        return WebAppState.LOGIN;
      case '/agb':
        return WebAppState.LOGIN;
      case '/disclaimer':
        return WebAppState.LOGIN;
      case '/webrtctest':
        return WebAppState.LOGIN;
      case '/imprint':
        return WebAppState.LOGIN;
      case '/consent':
        return WebAppState.LOGIN;
      case '/v':
        return WebAppState.VIDEO;
      case '/p':
        return WebAppState.VIDEO;
      default:
        return WebAppState.UNKNOWN;
    }
  }
}

// Create an instance of the StateObserver class
const stateObserver = StateObserver.getInstance();
